<template>
    <div id="authorized">
        <!-- Foxentry start -->
        <script type="text/javascript">
            var Foxentry;
        </script>
        <!-- Foxentry end -->

        <!-- global instant notifications common group -->
        <notification-messages/>
        <!-- global instant notifications maintenance group -->
        <notification-messages group="maintenance"/>
        <v-app>
            <app-bar @nav-icon-click="onNavIconClick"/>
            <app-navigation
                id="app-drawer"
                v-model="showNavigation"
                :is-mobile="$_isMobile || $_isTablet"
                :mobile-break-point="$_breakpoints.md"
                :expand="expand"
            />
            <div id="main-wrapper">
                <v-content>
                    <div id="layout-wrapper">
                        <div id="content-wrapper">
                            <div id="content">
                                <Sp16Banner v-if="isDoctor || isRegistrar"/>
                                <nuxt/>
                            </div>
                        </div>
                        <div v-if="isDoctor && !isDoctorsSpecificDetail" id="dashboard-wrapper">
                            <div>
                                <div id="doctor-dashboard">
                                    <app-dashboard
                                        v-if="statisticsFetched && doctorsStatisticsComp"
                                        :specialities="userInfo.specialities"
                                        :doctor-id="userInfo.id"
                                        :statistics="doctorsStatisticsComp"
                                        user-role="doctor"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </v-content>
                <v-footer
                    app
                    absolute
                    :height="$_isMobile ? 1050 : 400"
                    inset
                >
                    <div id="footer-wrapper" :class="{ 'justify-space-between': partnerships.length != 0 || generalInfo.imgSrc || !isDoctor }">
                        <div
                            v-if="partnerships.length != 0 || generalInfo.imgSrc"
                            id="partner-section"
                            class="footer-section"
                        >
                            <h4>Partneři aplikace</h4>
                            <div class="image-wrapper">
                                <div
                                    v-if="generalInfo.imgSrc"
                                    class="general-partner"
                                    :class="{ 'bottom-spacer' : partnerships.length != 0 }"
                                >
                                    <div class="partner-specialisation-label general-header">
                                        Generální partner
                                    </div>
                                    <div class="specialisation-partner">
                                        vzdelavanilekaru.cz
                                    </div>
                                    <a :href="addProtocol(generalInfo.url)" target="_blank">
                                        <v-img
                                            class="mt-4"
                                            width="150"
                                            height="45"
                                            :src="generalInfo.imgSrc"
                                        />
                                    </a>
                                </div>
                                <div v-if="partnerships.length != 0">
                                    <v-overlay :value="loadingPartnerships" absolute>
                                        <v-progress-circular indeterminate size="64"/>
                                    </v-overlay>
                                    <div class="partner-specialisation-label">
                                        Partneři specializací
                                    </div>
                                    <div id="specialisation-partner-wrapper">
                                        <div
                                            v-for="partnership in partnerships"
                                            :key="partnership.id"
                                            class="specialisation-partner"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <label v-on="on">{{ partnership.speciality.name }}</label>
                                                </template>
                                                <span>{{ partnership.speciality.name }}</span>
                                            </v-tooltip>

                                            <v-tooltip :disabled="!partnership.banner.tooltip" top>
                                                <template v-slot:activator="{on}">
                                                    <v-img
                                                        class="mt-4"
                                                        :class="{ 'cursor-pointer' : partnership.banner.link }"
                                                        :src="partnership.banner.url"
                                                        width="105"
                                                        height="45"
                                                        v-on="on"
                                                        @click.prevent="windowOpen(partnership.banner.link, '_blank')"
                                                    />
                                                </template>
                                                <span>{{ partnership.banner.tooltip }}</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="education" class="footer-section">
                            <h4>Vzdělávání lékařů</h4>
                            <div v-if="!isDoctor">
                                <div>
                                    <a href="https://soubory.vzdelavanilekaru.cz/Sta%C5%88te%20se%20partnerem.pdf" target="_blank">Staňte se partnerem</a>
                                </div>
                                <div v-if="!isDA">
                                    <a href="https://soubory.vzdelavanilekaru.cz/PODMI%CC%81NKY%20INZERCE%20NA%20INTERNETOVE%CC%81M%20PORTA%CC%81LU_0210204.pdf" target="_blank">Podmínky inzerce na webu</a>
                                </div>
                            </div>
                            <div>
                                <a href="https://soubory.vzdelavanilekaru.cz/GDPR.pdf" target="_blank">Zásady zpracování osobních údajů</a>
                            </div>
                            <div>
                                <a href="https://soubory.vzdelavanilekaru.cz/Podm%C3%ADnky%20u%C5%BEit%C3%AD.pdf" target="_blank">Všeobecné podmínky užití</a>
                            </div>
                            <div>
                                <a @click="showCookiesModal">Nastavení cookies</a>
                            </div>
                            <div v-if="isDoctor">
                                <div>
                                    <a href="https://soubory-vzdelavani-l.b-cdn.net/SP-16%20finalni-zneni.pdf" target="_blank">Stavovský předpis č. 16</a>
                                </div>
                                <div>
                                    <a href="https://www.youtube.com/watch?v=qgDfufKhs4Q&t=36s" target="_blank">O projektu</a>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="!isDoctor"
                            id="aditional-information"
                            class="footer-section"
                        >
                            <h4>Užitečné informace</h4>
                            <div v-if="isDA">
                                <a href="https://soubory-vzdelavani-l.b-cdn.net/VEŠKERÉ%20DŮLEŽITÉ%20INFORMACE%20PRO%20OS.pdf" target="_blank">Důležité informace</a>
                            </div>
                            <div>
                                <a href="https://soubory-vzdelavani-l.b-cdn.net/SP-16%20finalni-zneni.pdf" target="_blank">Stavovský předpis č. 16</a>
                            </div>
                            <div>
                                <a href="https://soubory-vzdelavani-l.b-cdn.net/Vyja%CC%81dr%CC%8Ceni%CC%81%20odborne%CC%81ho%20garanta%20(formula%CC%81r%CC%8C)%202024.doc" target="_blank">Vyjádření odborného garanta</a>
                            </div>
                            <div>
                                <a href="https://soubory.vzdelavanilekaru.cz/Prezen%C4%8Dn%C3%AD%20listina.xlsx" target="_blank">Prezenční listina</a>
                            </div>
                            <div>
                                <a href="https://soubory.vzdelavanilekaru.cz/Pozv%C3%A1nka.pdf" target="_blank">Pozvánka na vzdělávací akci</a>
                            </div>
                        </div>
                        <div id="contact-information" class="footer-section">
                            <h4>Kontaktní údaje</h4>
                            <div>
                                ČLK e-mail: <a href="mailto:vzdelavani2@clkcr.cz">vzdelavani2@clkcr.cz</a>
                            </div>
                            <div>
                                Tel. ČLK: <a href="tel:+420 234 760 703">+420 234 760 703</a>
                            </div>
                            <div>
                                Technická podpora: <a href="mailto:helpdesk@vzdelavanilekaru.cz">helpdesk@vzdelavanilekaru.cz</a>
                            </div>
                        </div>
                    </div>
                    <div v-if="appVersion" id="version">{{ `verze: ${appVersion}` }}</div>
                </v-footer>

                <!-- Modals -->
                <modal-logout :value="logoutClick"/>
                <modal-sign-in :value="showAuthLoginModal"/>
                <!-- Logout overlay -->
                <v-overlay :value="showLogoutOverlay" opacity="0.8">
                    <v-progress-circular indeterminate size="64"/>
                </v-overlay>

                <!-- Cookies confirm-->
                <cookies-settings/>

                <!-- Snackbar for demo page -->
                <v-snackbar
                    id="demo-snackbar"
                    v-model="displayDemoPageSnackbar"
                    :timeout="0"
                >
                    {{ $t('c_common.c_labels.c_demoNotification') }}
                    <template>
                        <v-btn
                            color="white"
                            icon
                            @click="demoPageSnackbar = false"
                        >
                            <i class="fal fa-times fa-lg"/>
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-app>
    </div>
</template>

<script>
    import moment from 'moment';
    import AppNavigation from '../components/AppNavigation';
    import AppBar from '../components/AppBar';
    import AppDashboard from '../components/AppDashboard';
    import ModalLogout from '../components/ModalLogout';
    import ModalSignIn from '../components/ModalSignIn';
    import NotificationMessages from '../components/NotificationMessages';
    import cssBreakpointsMixin from '../mixins/cssBreakpointsMixin';
    import notificationsMixin from '../mixins/notificationsMixin';
    import tokenMixin from '../mixins/tokenMixin';
    import menuMixin from '../mixins/menuMixin';
    import { windowOpen } from '@/utils/htmlUtils';
    import {
        getAccessToken,
        getUserRole,
        getDoctorsSpecialities,
        getShowAuthLoginModal,
        getUserID,
        getUserInfo,
        getLogoutUser,
        setUserStatus
    } from '@/utils/localStorageUtils';
    import { addProtocol } from '@/utils/linkUtils';
    import { deepCopy } from '@/utils/arrayUtils';
    import 'url-search-params-polyfill';
    import CookiesSettings from '../components/CookiesSettings.vue';
    import Sp16Banner from '../components/Sp16Banner.vue';

    export default {
        name: 'Authorized',
        components: { AppNavigation, AppBar, AppDashboard, ModalLogout, ModalSignIn, NotificationMessages, CookiesSettings, Sp16Banner },
        mixins: [cssBreakpointsMixin, notificationsMixin, tokenMixin, menuMixin],
        data() {
            return {
                showNavigation: false,
                showSPBanner: true,
                expand: false,
                copyrightYear: moment().format('YYYY'),
                partnerships: [],
                doctorsSpecialities: [],
                loadingPartnerships: false,
                userInfo: null,
                doctorsStatistics: {
                    current_credits: 0,
                    total_credits: 0,
                    diploma_valid_until: '',
                    last_diploma_request_status: ''
                },
                statisticsFetched: false,
                userRole: '',
                registrarStatus: '',
                userId: '',
                notificationsIntervalId: '',
                requestsIntervalId: '',
                statusIntervalId: '',
                storageShowAuthLoginModal: '',
                routeBack: '',
                appVersion: '',
                generalInfo: {
                    imgSrc: '',
                    url: ''
                },
                demoPageSnackbar: true,
                addProtocol,
                windowOpen
            };
        },
        computed: {
            defaultActiveItem() {
                return this.$store.getters.getUserDefaultActiveItem;
            },
            logoutClick() {
                return this.$store.getters.getLogoutClick;
            },
            showAuthLoginModal() {
                return this.$store.getters.getShowAuthLogin;
            },
            showLogoutOverlay() {
                return this.$store.getters.getShowLogoutOverlay;
            },
            isDA() {
                return this.userRole === 'district_association';
            },
            isRegistrar() {
                return this.userRole === 'registrar';
            },
            isDoctor() {
                return this.userRole === 'doctor';
            },
            doctorsStatisticsComp() {
                return this.$store.getters.getDoctorsStatistic;
            },
            isDoctorsSpecificDetail() {
                return this.$route.name === 'zadosti-kredity-id' || this.$route.name === 'zadosti-diplomy-id' || this.$route.name === 'videa-prenosy-id';
            },
            updateProfileCount() {
                return this.$store.getters.getUpdateProfileCount;
            },
            backendMaintenance() {
                return this.$store.getters.getBackendMaintenance;
            },
            displayDemoPageSnackbar() {
                return ['demo.vzdelavanilekaru.cz', 'dev.vzdelavanilekaru.cz', 'testing.vzdelavanilekaru.cz'].includes(window.location.hostname) && this.demoPageSnackbar;
            }
        },
        watch: {
            updateProfileCount() {
                this.userInfo = getUserInfo();
            },
            backendMaintenance() {
                if (this.backendMaintenance) {
                    this.$_notification(this.$t('c_notifications.c_maintenance'), 'maintenance', this.$t('c_notifications.c_maintenanceTitle'), -1, 'maintenance');
                } else {
                    this.$_clearNotifications('maintenance');
                }
            }
        },
        beforeCreate() {
            // Set token for all requests
            this.$axios.setToken(getAccessToken(), 'Bearer');
        },
        async mounted() {
            this.appVersion = process.env.appVersion;
            this.storageShowAuthLoginModal = getShowAuthLoginModal();
            // Check token on mouse move event
            window.addEventListener('mousemove', this.$_checkAccessTokenExpiration);
            /* eslint-disable */
            const e = document.querySelector("script"), s = document.createElement('script');
            s.setAttribute('type', 'text/javascript');
            s.setAttribute('async', 'true');
            s.setAttribute('src', 'https://cdn.foxentry.cz/lib');
            e.parentNode.appendChild(s);
            s.onload = function(){ Foxentry = new FoxentryBase(process.env.localDev ? 'D1oPUKtMgV' : 'LFr8h1wK4L'); }
            /* eslint-enable */

            // Listen whether user logged out from another app tab
            window.addEventListener('storage', event => this.onUserLogout(event));

            if (Object.keys(this.$route.query).includes('loginRedirectUrl')) {
                const routeQuery = deepCopy(this.$route.query);
                delete routeQuery.loginRedirectUrl;
                if (Object.keys(this.$route.query).includes('redirectAlreadyAuthorized')) {
                    delete routeQuery.redirectAlreadyAuthorized;
                }
                await this.$router.push({ name: this.$route.name, query: routeQuery });
            }

            this.userInfo = getUserInfo();
            this.userRole = getUserRole();
            this.userId = getUserID();

            if (this.storageShowAuthLoginModal !== 'true') {
                this.checkUserRole();
                if (this.userRole !== 'super_admin') {
                    this.setCheckRequests();
                    await this.checkRequests();
                }
                if (this.userRole === 'registrar' && this.registrarStatus !== 'approved') {
                    this.setCheckRegistrarStatus();
                    await this.checkRegistrarStatus();
                }
                this.setCheckNotifications();
                await this.checkNotifications();
            }
        },
        beforeDestroy() {
            window.removeEventListener('mousemove', this.$_checkAccessTokenExpiration);
            window.removeEventListener('storage', this.onUserLogout);
            // Stop checking notifications and requests after authorized layout destroy
            clearInterval(this.notificationsIntervalId);
            clearInterval(this.requestsIntervalId);
            // Stop checking registrar status after unapproved registrar logout
            if (this.statusIntervalId) {
                clearInterval(this.statusIntervalId);
            }
        },
        methods: {
            async fetchPartnerships() {
                try {
                    this.loadingPartnerships = true;
                    const params = new URLSearchParams();
                    this.doctorsSpecialities.forEach((speciality) => {
                        params.append('specialitiesIds', speciality.id);
                    });
                    ({ partnerships: this.partnerships } = await this.$axios.$get('/premium/speciality-partnerships/active', {
                        params: params
                    }));
                } catch (error) {
                    this.$_handleConnectionError(error);
                } finally {
                    this.loadingPartnerships = false;
                }
            },
            async fetchDoctorsStatistic() {
                ({ statistics: this.doctorsStatistics } = await this.$axios.$get(`/users/${this.userInfo.id}/credits/statistics`));
                this.$store.commit('setDoctorsStatistic', this.doctorsStatistics);
                this.statisticsFetched = true;
            },
            async checkRequests() {
                try {
                    await this.$_checkTokenExpiration();
                    let requestsCount = 0;
                    let daRequestsCount = 0;
                    let doctorRequestsCount = 0;
                    const { statistics } = await this.$axios.$get(`/users/${this.userId}/requests/statistics`);
                    switch (this.userRole) {
                        case 'cmc':
                            statistics.forEach((statistic) => {
                                requestsCount += (statistic.updated_by_applicant + statistic.pending);
                            });
                            break;
                        case 'registrar':
                            statistics.forEach((statistic) => {
                                if (statistic.type === 'register_activity') {
                                    requestsCount = statistic.updated_by_approver;
                                }
                            });
                            break;
                        case 'district_association':
                            statistics.forEach((statistic) => {
                                if (statistic.type === 'credit' || statistic.type === 'diploma') {
                                    daRequestsCount += (statistic.updated_by_applicant + statistic.pending);
                                } else if (statistic.type === 'register_activity') {
                                    requestsCount = statistic.updated_by_approver;
                                }
                            });
                            break;
                        case 'doctor':
                            statistics.forEach((statistic) => {
                                doctorRequestsCount += statistic.updated_by_approver;
                            });
                            break;
                    }
                    this.$store.commit('setRequestsCount', requestsCount);
                    if (this.isDA) {
                        this.$store.commit('setDaRequestsCount', daRequestsCount);
                    }
                    if (this.isDoctor) {
                        this.$store.commit('setDoctorRequestsCount', doctorRequestsCount);
                    }
                } catch (error) {
                    this.$_handleConnectionError(error);
                }
            },
            async checkNotifications() {
                try {
                    await this.$_checkTokenExpiration();
                    const notificationsCount = await this.$axios.$get(`/users/${this.userId}/notifications/metadata`);
                    this.$store.commit('setNotificationsCount', notificationsCount.metadata.unread_count);
                    this.$store.commit('setTotalNotificationsCount', notificationsCount.metadata.total_count);
                } catch (error) {
                    this.$_handleConnectionError(error);
                }
            },
            async checkRegistrarStatus() {
                try {
                    ({ request: { status: this.registrarStatus } } = await this.$axios.$get(`/registrars/${this.userId}`, {
                        params: { includeRequest: true }
                    }));
                    setUserStatus(this.registrarStatus);
                    this.$store.commit('setRegistrarStatusFetched');
                } catch (error) {
                    this.$_handleConnectionError(error);
                }
            },
            checkUserRole() {
                if (this.userRole === 'doctor') {
                    this.fetchDoctorsStatistic();
                    this.doctorsSpecialities = getDoctorsSpecialities();
                    this.fetchPartnerships();
                }
            },
            setCheckRegistrarStatus() {
                // Check registrar status in one hour interval
                this.statusIntervalId = setInterval(async() => {
                    await this.checkRegistrarStatus();
                    if (this.registrarStatus === 'approved') {
                        clearInterval(this.statusIntervalId);
                        await this.$router.push({ name: this.defaultActiveItem });
                    }
                }, 1000 * 60 * 60);
            },
            setCheckRequests() {
                // Check requests in 15 minutes interval
                this.requestsIntervalId = setInterval(() => this.checkRequests(), 1000 * 60 * 15);
            },
            setCheckNotifications() {
                // Check notifications in 15 minutes interval
                this.notificationsIntervalId = setInterval(() => this.checkNotifications(), 1000 * 60 * 15);
            },
            onNavIconClick() {
                this.expand = !this.expand;
                if (this.$_isMobile || this.$_isTablet) {
                    this.showNavigation = !this.showNavigation;
                }
            },
            onUserLogout(event) {
                // Listen for user logout
                const logoutUser = getLogoutUser();
                if (event.key === 'logoutUser' && logoutUser) {
                    this.$_userLogout();
                }
            },

            showCookiesModal() {
                this.$store.commit('setShowCookiesModal', true);
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';

    #authorized {
        ::v-deep #not-found {
            color: gray;
            padding: 1rem;
        }

        .theme--light.v-footer {
            background: transparent;
            padding: 0;
            color: gray;

            #version {
                position: absolute;
                bottom: 1rem;
                right: 2rem;
            }

            @media screen and (max-width: breakpoint('md')) {
                bottom: -5rem!important;
            }

        }

        .cursor-pointer {
            cursor: pointer;
        }

        #demo-snackbar {
            ::v-deep .v-snack__wrapper  {
                background: color('dark-orange');
            }
        }

        #footer-wrapper {
            background: #e7eaef75;
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 2rem 3rem 3.5rem 3rem;
            display: flex;
            font-size: 0.9375rem;

            a {
                color: gray;
                text-decoration: underline;
                white-space: nowrap;

                &:hover {
                    text-decoration: underline;
                }
            }

            h4 {
                color: gray;
                margin-bottom: 1rem;
                border-bottom: 1px solid gainsboro;
                padding-bottom: 0.5rem;
                font-weight: 400;
                font-size: 1.0625rem;
            }

            #partner-section {
                .partner-specialisation-label {
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                }

                .general-partner {
                    margin-right: 3rem;
                }

                 #specialisation-partner-wrapper {
                    display: flex;

                    .specialisation-partner {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 9.375rem;

                        &:first-child {
                            margin-right: 3rem;
                        }

                        .v-image {
                            filter: grayscale(100%);
                        }
                    }
                }
            }
            .image-wrapper {
                display: flex;

                .banner-image {
                    margin-right: 3rem;
                }
            }

            .footer-section:not(:last-of-type) {
                margin-right: 4%;
            }

            @media screen and (max-width: 1370px) {
                font-size: 0.8rem;
            }

            @media screen and (max-width: breakpoint('md')) {
                flex-direction: column;
                padding: 2rem;
                text-align: center;
                font-size: 0.9375rem;

                h4 {
                    font-size: 1.5rem;
                }

                .footer-section {
                    width: 100%;
                    margin-bottom: 5rem;
                }

                #partner-section {

                    .image-wrapper {
                        flex-direction: column;
                    }

                    #specialisation-partner-wrapper {
                        justify-content: center;
                    }

                    .general-partner {
                        max-width: 10rem;
                        margin: auto;
                    }

                    .bottom-spacer {
                        padding-bottom: 4rem;
                    }
                }
            }

            @media screen and (max-width: breakpoint('sm')) {
                #partner-section #specialisation-partner-wrapper {
                    flex-direction: column;

                    .specialisation-partner {
                        max-width: 100%;
                        margin-right: 0!important;
                        margin-bottom: 1rem;

                        .v-image {
                            margin: auto;
                        }
                    }
                }
            }
        }
    }

    #main-wrapper {
        display: flex;
        flex-direction: column;
        background: #f5f7fb;
        background-size: 100%;
        height: 100%;

        .container {
            padding: 1rem;
        }

        #spacer {
            width: 23rem;
        }

        #layout-wrapper {
            display: flex;
            justify-content: center;

            #content-wrapper {
                padding: 1rem 3rem;
                margin-top: 2rem;
                width: 100%;
            }

            #dashboard-wrapper {
                padding: 1rem 3rem 1rem 0;
                margin-top: 2rem;
            }

            @media only screen and (max-width: 1750px) and (min-width: 950px) {
                flex-direction: column;

                #dashboard-wrapper {
                    order: 1;
                    padding: 0 2rem;
                }

                #content-wrapper {
                    order: 2;
                    width: 100%;
                    padding: 0 2rem;
                }

            }

            @media only screen and (max-width: 950px) and (min-width: 200px) {
                flex-direction: column;

                #dashboard-wrapper {
                    order: 2;
                    padding: 1rem;
                }

                #content-wrapper {
                    padding: 1rem;
                    order: 1;
                    width: 100%;
                }

            }
        }

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .v-content {
                display: block;
                max-width: 100vw;
            }
        }
    }

    ::v-deep .v-dialog {

        .error-messages-wrapper.message.error-message {
            margin: 2rem 0;
            border: 0;
        }

        .fa-sign-out-alt, .fa-user-clock {
            font-size: 2.2rem;
        }

        .v-card {
            .v-card__text {
                font-size: 1rem;
                padding: 2.5rem;
                padding-top: 0;
            }

            .headline {
                padding: 2.5rem;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                color: color('font-primary-color')
            }

            .theme--light.v-btn.secondary-button {
                color: color('primary');
                background-color: color('secondary')
            }
        }

        .secondary {
            background: color('secondary');
        }

        .btn-wrapper {
            display: flex;
            justify-content: flex-end;

            .v-btn:first-of-type {
                margin-right: 1rem;
            }
        }

        @media screen and (max-width: breakpoint('sm')) {
            .v-card {
                .headline {
                    padding: 1rem;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
                 .v-card__text {
                    padding: 1rem;
                 }
            }

            .btn-wrapper {
                flex-direction: column-reverse;
                .v-btn:first-of-type {
                    margin: 1em 0 1rem 0;
                }
            }
        }
    }

    @media only screen and(max-width: breakpoint('sm')) {
        #content-wrapper {
            .container {
                padding: 0!important;
            }

            main {
                padding-top: 80px!important;
            }

            #content-card {
                padding: 1rem;
            }

            #main-col {
                padding: 12px 0;
            }
            .row {
                margin-right: 0;
                margin-left: 0;
            }

            #partners-section {
                margin: 5rem -1rem -1rem -1rem!important;
                text-align: center;

                .spacer {
                    display: none;
                }

                .v-image {
                    margin: auto;
                }

                #partner-specialisation-label {
                    margin-top: 2rem;
                }

                #specialisation-partner-wrapper{
                    flex-direction: column;

                    .specialisation-partner {
                        margin-right: auto!important;
                        margin-left: auto;
                        margin-bottom: 2rem;
                        white-space: inherit;
                    }
                }

            }
        }
    }
</style>
